.slickslide img {
    margin: 0 auto;
    object-fit: cover;
    height: 100%;
}

.slick-track
{
    display: flex !important;
}

.slick-slide
{
    height: inherit !important;
}

.slick-slide div
{
    height: 100% !important;
}

.slickslide p {
    position: absolute;
    top: 20%;
    width: 100%;
    color: white;
    font-size: 1.2rem;
    font-family: Amatic SC, Helvetica, sans-serif;
    font-weight: bold;
}

.slickslide {
    transform: scale(1);
    transition: transform 300ms;
    opacity: 0.6;
    text-align: center;
}

.activeSlide {
    transform: scale(1);
    opacity: 1;
}

.arrow {
    position: absolute;
    cursor: pointer;
    z-index: 10;
}

.arrow svg {
    transition: color 300ms;
}

.arrow svg:hover {
    color: white;
}

.next {
    right: -3em;
    top: 15%;
}

.prev {
    left: -3em;
    top: 15%;
}