body {
  margin: 0;
  padding: 0;
}

#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
}

h3 {
  font-family: "Amatic SC", cursive;
  font-size: 3em;
  font-weight: 900;
}

img {
  width: 100%;
}

video {
  width: 100%;
}

#logo {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0px auto;
  width: 100%;
  height: 5vh;
  padding: 10px 0 10px 0;
  border: none;
  border-radius: 3px;
  font-size: 1em;
  text-align: center;
  color: #fff;
  background: black;
  font-family: "Amatic SC", cursive;
  font-weight: bold;
  text-transform: uppercase;
}

#logo img {
  width: 3em;
  /* padding-right: 2em; */
}

.mapboxgl-ctrl-pitchtoggle-3d {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+ICAgIDx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBkeT0iLjM1ZW0iIHN0eWxlPSJmb250LXNpemU6IDE0cHg7IGZvbnQtZmFtaWx5OiAnSGVsdmV0aWNhIE5ldWUnLEFyaWFsLEhlbHZldGljYSxzYW5zLXNlcmlmOyBmb250LXdlaWdodDogYm9sZDsgdGV4dC1hbmNob3I6IG1pZGRsZTsiPjNEPC90ZXh0Pjwvc3ZnPg==);
}

.mapboxgl-ctrl-pitchtoggle-2d {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIzMCIgaGVpZ2h0PSIzMCI+ICAgIDx0ZXh0IHg9IjUwJSIgeT0iNTAlIiBkeT0iLjM1ZW0iIHN0eWxlPSJmb250LXNpemU6IDE0cHg7IGZvbnQtZmFtaWx5OiAnSGVsdmV0aWNhIE5ldWUnLEFyaWFsLEhlbHZldGljYSxzYW5zLXNlcmlmOyBmb250LXdlaWdodDogYm9sZDsgdGV4dC1hbmNob3I6IG1pZGRsZTsiPjJEPC90ZXh0Pjwvc3ZnPg==);
}

.mapboxgl-ctrl {
  bottom: 10%;
}

.mapboxgl-popup-close-button {
  font-size: 1.5rem;
  color: #226600;
  font-weight: 800;
  font-family: sans-serif;
}

.mapboxgl-popup-content {
  padding: 40px 10px 15px;
}

.mapboxgl-popup-close-button:focus {
  outline: none;
}

.mapboxgl-popup-content h3 {
  display: block;
  padding: 10px 0px 10px 0px;
  margin: 0;
}


.mapboxgl-ctrl-attrib,
.mapboxgl-ctrl-logo {
  display: none !important;
}


#logo {
  z-index: 10;
}

a {
  color: #404040;
  text-decoration: none;
}

a:hover {
  color: #101010;
}

.listings {
  height: 80%;
  overflow: auto;
  padding-bottom: 0;
  width: 100%;
}

.listings .item {
  display: block;
  border-bottom: 1px solid #eee;
  padding: 10px;
  text-decoration: none;
}

.listings .item:last-child {
  border-bottom: none;
}
.listings .item .title {
  display: block;
  color: #00853e;
  font-weight: 700;
}

.listings .item .title small {
  font-weight: 400;
}

.listings .item.active .title,
.listings .item .title:hover {
  color: #8cc63f;
}
.listings .item.active {
  background-color: #f8f8f8;
}
::-webkit-scrollbar {
  width: 3px;
  height: 3px;
  border-left: 0;
  background: rgba(0, 0, 0, 0.1);
}
::-webkit-scrollbar-track {
  background: none;
}
::-webkit-scrollbar-thumb {
  background: #00853e;
  border-radius: 0;
}


@media only screen and (max-width: 600px) {
  #logo {
    font-size: 0.5em;
  }
}