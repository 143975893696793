
.button__holderAoc:after {
  content: "";
  background: rgba(26, 26, 26, 0.7);
}
.button__holderAoc h2 {
  margin-right: 1em;
  text-align: center;
  color: #c6613e;
}

.aoc {
  z-index: 1;
  width: 100%;
  height: 3em;
  color: #ffffff;
  background: #c6613e;
  border-radius: 50%;
  border: none;
  transition: box-shadow 400ms cubic-bezier(0.2, 0, 0.7, 1), transform 200ms cubic-bezier(0.2, 0, 0.7, 1);
}
.aoc:after {
  content: "AoC Videos";
  font-family: "Amatic SC";
  font-style: "Bold";
  font-size: 1em;
  line-height: 1.1em;
}
.aoc:hover {
  background: #629d99;
  transform: rotate(0deg);
  box-shadow: 0 0 1px 15px rgba(98, 157, 153, 0.4), 0 0 1px 30px rgba(255, 255, 255, 0.1), 0 0 1px 45px rgba(98, 157, 153, 0.1);
}
.aoc:active {
  color: #93e4df;
}

.button__holderPano .pano {
  z-index: 1;
  width: 100%;
  height: 3em;
  color: #ffffff;
  background: #c6613e;
  border-radius: 50%;
  border: none;
  transition: box-shadow 400ms cubic-bezier(0.2, 0, 0.7, 1), transform 200ms cubic-bezier(0.2, 0, 0.7, 1);
}
.button__holderPano .pano:after {
  content: "360";
  font-family: "Amatic SC";
  font-style: "Bold";
  font-size: 1em;
  line-height: 1.1em;
}
.button__holderPano .pano:hover {
  background: #629d99;
  transform: rotate(0deg);
  box-shadow: 0 0 1px 15px rgba(98, 157, 153, 0.4), 0 0 1px 30px rgba(255, 255, 255, 0.1), 0 0 1px 45px rgba(98, 157, 153, 0.1);
}
.button__holderPano .pano:active {
  color: #93e4df;
}

.button__holderSubscribe h2 {
  margin-right: 1em;
  text-align: center;
  color: #c6613e;
}

.sub {
  z-index: 1;
  width: 100%;
  height: 3em;
  color: #ffffff;
  background: #c6613e;
  border-radius: 50%;
  border: none;
  transition: box-shadow 400ms cubic-bezier(0.2, 0, 0.7, 1), transform 200ms cubic-bezier(0.2, 0, 0.7, 1);
}
.sub:after {
  content: "Subscribe";
  font-family: "Amatic SC";
  font-style: "Bold";
  font-size: 1em;
  line-height: 1.1em;
}
.sub:hover {
  background: #629d99;
  transform: rotate(0deg);
  box-shadow: 0 0 1px 15px rgba(98, 157, 153, 0.4), 0 0 1px 30px rgba(255, 255, 255, 0.1), 0 0 1px 45px rgba(98, 157, 153, 0.1);
}
.sub:active {
  color: #93e4df;
}

.button__holderCultural h2 {
  margin-right: 1em;
  text-align: center;
  color: #c6613e;
}

.cul {
  z-index: 1;
  width: 100%;
  height: 3em;
  color: #ffffff;
  background: #c6613e;
  border-radius: 50%;
  border: none;
  transition: box-shadow 400ms cubic-bezier(0.2, 0, 0.7, 1), transform 200ms cubic-bezier(0.2, 0, 0.7, 1);
}
.cul:after {
  content: "Cultural Map";
  font-family: "Amatic SC";
  font-style: "Bold";
  font-size: 1em;
  line-height: 1.1em;
}
.cul:hover {
  background: #629d99;
  transform: rotate(0deg);
  box-shadow: 0 0 1px 15px rgba(98, 157, 153, 0.4), 0 0 1px 30px rgba(255, 255, 255, 0.1), 0 0 1px 45px rgba(98, 157, 153, 0.1);
}
.cul:active {
  color: #93e4df;
}