/* @import url("https://fonts.googleapis.com/css?family=Amatic+SC:wght@700&display=swap");
body {
  background: #080808;
  color: white;
  font-family: "Amatic SC", sans-serif;
  line-height: 1.5;
} */

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Amatic SC", serif;
}

.c-section {
  height: 100vh;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.c-section__inner {
  height: 100%;
}
